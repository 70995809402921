.qr-img-scan{
    width: 120px;
    height: 120px; 
    margin-left: 10px;
    margin-top: 10px;  
}
.tickt-detail{
    display: flex;
    background-color: #a7a7a747;
}
.color-name{
    color: #002e91;
    font-weight: 600;
    font-size: 18px;  
}
.date-ticket{
    display: flex;
}
p{
    margin-bottom: 0;
}
.container-mytick{
    margin-left: 20px;
}
.no-tick{
    text-align: center;
    background-color: #002e91;
    color: #ffffff;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.tickt-detail{
    margin-top: 10px;
}