.entertain-category{
    width: 100%;
    padding-bottom: 8%;
}
.box-Img-three{
    width: 100%;
    height: 345px;
    object-fit: cover;
    transition: all .5s;
}
.category-three-box{
   display: block !important;
   border-radius: 18px;
   background-color: white;
   box-shadow: 4px 6px 19px 0 #0000001c;
}
.second-column{
   margin-top: 30px;
}
.section-two-category .category-three-box img {
    border-radius: 18px;
}
.subtitle-en{
	text-align: center;
}
.font-weight-bold-en{
	text-align: center ;
}
.list-box-flex > a {
  font-size: 17px;
  color: #252525;
  text-decoration: underline;
}
.list-box-flex {
    display: block !important;
    justify-content: safe !important;
}
.entertain-category > h3 {
    font-weight: 550;
}

/* Common style */
.grid figure {
	position: relative;
	float: left;
	overflow: hidden;
	margin: 10px 1%;
	min-width: 320px;
	max-width: 480px;
	max-height: 360px;
	width: 48%;
	background: #3085a3;
	text-align: center;
	cursor: pointer;
}

.rentCategory-text{
    background-color: rgb(255, 255, 255);
    color: #000;
    padding: .7rem;
}

.section-two-category{
 margin-top: 30px;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.9;
}

.grid figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}

.grid figure figcaption {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
/*special styling*/
.effect-lily {
    transition: all .4s;
    border-radius: 17px;
    border: 8px solid white;
    box-shadow: 4px 6px 19px 0 #0000001c;
}
.effect-lily h3 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 0;
  color: white;
}


figure.effect-lily figcaption {
    transition: .3s;
	text-align: left;
    background: linear-gradient(rgba(0, 0, 0, 0.08),rgba(0, 0, 0, 0.925));
}

figure.effect-lily figcaption a > div {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1em;
    width: 100%;
    height: 50%;
    padding-top: 6.1rem;
    font-family: Arial, Helvetica, sans-serif;
}
.effect-lily figcaption .more {
  font-size: 14px;
  font-weight: 550;
  color: #e8e8e8;
  position: absolute;
  opacity: 0;
  transition: all .3s;
  bottom: 1rem;
  text-decoration: underline;
  cursor: pointer;
  left: 23px;
}
.effect-lily:hover figcaption .more{
  bottom: 1.5rem;
  opacity: 1;
}

.effect-lily:hover figcaption {
  background: linear-gradient(rgba(15, 15, 15, 0.08),rgb(0, 0, 0));
}

.effect-lily:hover .box-Img-three{ 
  transform: scale(1.1);
  transition: all .5s;
}
.more{
  text-decoration: underline;  
}

.effect-lily p {
    font-size: 16px;
    color: #d5d5d5;
}

figure.effect-lily h2 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}


@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro');

.booking-hero-bgd{
    background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url('../../assets/Images/eventEleven.jpg');
    background-repeat: no-repeat;
    width:100%;
    height: 100vh;
    background-size: cover;
    border-bottom-left-radius: 1.8rem;
    border-bottom-right-radius: 1.8rem;
}

   .wrapper-container-home{
    width: 95%;
    margin: 0 auto;
}
.bookindapp-Container{
    width: 75%;
    margin: 0 auto; 
}
.hero-container{
    text-align: center;
    align-items: center;
    color: #ffffff;
    margin-top: 10%;
}
.discover-txt {
    font-size: 69px;
    margin: -21px 0;
    text-transform: uppercase;
}
.music-txt{
    font-size: 45px;
    text-align: center;
}
.music-color{
    color: #0ee0c2;
    font-size: 45px;
}


.hero-category-container{
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 50px;
}
.catg-main{
    display: flex;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 22px;

}
.choose-categr{
    font-size: 18px;
    color: #666;
    font-weight: 300;
}
.part-select{ 
    margin: 10px;
    padding-left: 40px;
    padding-right: 20px;}
   

.search-btn{
    border: 0;
    background-color: #002e91;
    color: #ffff;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    margin-top: 14px;
}
.bookingOffer-img{
    width: 85%;
    height: 400px;
    border-radius: 20px;
}
.book-box-img{
 margin-top: 30px;
 margin-bottom: 30px;
 display: flex;
 justify-content: center;
}
.ticket-wrap-para {
    font-size: 18px;
    position: relative;
    left: 30%;
    background-color: #ffff;
    z-index: 9;
    padding: 1rem;
    top: 13%;
    border-radius: 12px;
    box-shadow: 1px 9px 23px 0px #0000002f;
    color: #4f4f4f;
}
.sideticket-img{
    width: 100%;
    height: 380px;
    object-fit: cover;
    border-radius: 18px;
}
.margin-left-ticket{
    margin-left: 20px;
}
.sub-tick-txt{
    display: flex;
    justify-content:space-evenly;
    margin-top: 30%;
}
.text-red{
    color: #d9072a 
}
.started-box{
    display: flex;
    justify-content: center;
    margin-right: 10%;
}
.Started-btn-btn{
    border: 0;
    background-color: #002e91;
    color: #ffff;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    margin-top: 14px; 
}
.org-head-txt{
    color: #16151a;
    font-size: 18px;
    font-weight: bold;
}
.user-img-pf{
    width: 40px;
    height: 40px;
    border-radius: 40px;
}
.pb-3-txt{
    margin: 5px;
  font-size: 18px;
  color: #002e91 !important;
}

@media (max-width: 768px) {
  
    .catg-main{
        display: block;
    
    }
    .container{
        margin-top: 20px;
    }
    .bookingOffer-img {
        width: 100%;
        height: 160px;
    }   
    .ticket-wrap-para {
        font-size: 18px;
        left:0;
        background-color: #ffff;
        z-index: 9;
        padding: 22px;
        top:0;
    }
    .sub-tick-txt{
        margin-top: 70%;
    }
    .pb-3-txt {
        margin: 5px;
        font-size: 10px;
        color: #002e91 !important;
    }
    .rentDaap-Img {
        width: 20% !important;
        height: 110px;
    }
    .discover-txt {
        font-size: 36px;
        margin-top: -7px;
    }
    .music-txt {
        font-size: 35px;
        text-align: center;
    }
    .music-color {
        color: #0ee0c2;
        font-size: 30px;
    }
}

.hero-category-container input:focus{
  box-shadow: none;   
}

.hero-category-container h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px;
}
.hero-category-container input,.hero-category-container select {
  outline: none;
  border: none;
  padding: 0;
}
.catg-main{
  padding: 0;
  border-radius: 12px !important;   
}
.part-select {
  padding-right: 0 !important;
  padding-left: 25px !important;
  margin-bottom: 0;
}
.part-select button {
  cursor: pointer;
}

button{
  cursor: pointer;  
}

.section-two-category > .row [class*='col-md-4']{
  display: flex;  
}
.text-clamp-1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.event-name{
  font-size: 17px !important;
  color: #002e91 !important;
  font-weight: 550;
  text-decoration: none !important;
}
.hero-category-container .form-select {
  background: white;
}


#video .container {
    width: fit-content;
    text-align: center;
  }
  .reflection-text {
    font-size: 2.2rem;
    font-weight: 550;
    margin-bottom: -2px;
  }
  #video .container p {
    margin-bottom: 1rem;
  }
  #video .container iframe {
    border-radius: 12px;
  }
  @media screen and (max-width: 500px) {
    #video iframe{
      width: 100%;
      height: 237px;  
    }
}