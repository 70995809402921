.upload-pic1{
    width: 100px;
    height: 80px;
    margin: 20px;
}

.head-create{
    margin-top: 30px;
}
 html, body {
	 font-family: helvetica, arial;
	 background: #eee;
}
 main {
	 width: 80%;
	 margin: 0 auto;
	 padding-top: 20%;
}

 .btn, button {
	 color: #fff;
	 background-color: #09f;
	 border: 1px solid #09f;
	 text-align: center;
	 display: inline-block;
	 vertical-align: middle;
	 white-space: nowrap;
	 margin: 0.6em 0.6em 0.6em 0;
	 padding: 0.35em 0.7em 0.4em;
	 text-decoration: none;
	 width: auto;
	 position: relative;
	 border-radius: 4px;
	 user-select: none;
	 outline: none;
}
 .btn:hover, button:hover {
	 background-color: #ddd;
	 color: #333;
}
 .btn:active, button:active {
	 background-color: #ccc;
	 box-shadow: 0 !important;
	 top: 2px;
	 box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}


.upload-img input {
	border: 1px solid rgb(156, 154, 154);
	padding: .3rem;
	width: 96%;
	color: rgb(119, 119, 119);
	border-radius: 4px;
	margin-right: 5px;
}

.left img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.file-wrapper {
	cursor: pointer;
	display: inline-block;
	position: relative;
}

.file-wrapper input {
	cursor: pointer;
	font-size: 100px;
	height: 100%;
	filter: alpha(opacity=1);
	opacity: 0.01;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 9;
}

.left {
   width: 13rem;
   height: 8rem;
   margin: auto;
   margin-bottom: 1rem;
   border-radius: 10px;
   overflow: hidden;
}
.upload-img {
   padding: 6px;
   border: 2px dashed #0000002b;
}
.right {
   display: flex;
   align-items: center;
}

.success-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .5rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.success-header .success-icon {
  font-size: 40px;
  color: #53c42b;
  margin-bottom: 5px;
}
.success-color {
  font-size: 24px;
  color: #002E91;
  font-weight: bold;
}
.success-animation{
  position: absolute;
  top: -1rem;	
  left: -1rem;	
  bottom: -1rem;	
  right: -1rem;	
  background-image: url('https://img1.picmix.com/output/stamp/normal/8/1/8/0/1540818_fa9b6.gif');
}