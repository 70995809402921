.bookDaap-Img{
    width: 50px;
    height: 90px;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}
.navbar-dark .navbar-nav .nav-link{
    color: #ffffff;
}
.chat-dropdown {
    line-height: 1.6;
    position: absolute;    
    border-radius: 10px;
 }
 .chat-dropdown a {
    line-height: 1;
    padding: 5px 0;
    text-decoration: underline;
    color: rgb(36, 84, 241) !important;
}