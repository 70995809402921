.listing-Img-three {
    width: 100%;
    height: 335px;
    object-fit: cover;
    border-radius: 15px;
}
.category-three-box{
   position: relative;
   overflow: hidden;
   padding: 11px;
   border-radius: 20px;
   box-shadow: 4px 6px 19px 0 #0000001c;
}

.listing-text{
  color: #000000;
  margin: 0 auto;
  background-color: #ffffff;
  font-weight: 500;
  padding: 13px;
  font-size: 21px;
}
  .list-box-flex{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
  .location-txt{
      font-size: 15px;
  }
  .small-ev-box{
      width: 60px;
      height: 60px;
      border:1px solid #fff;
      position: absolute;
      top: 16px;
      left: 14px;
      margin: 10px;
  }
  .ev-month{
      font-size: 14px;
      color: #fff;
      background-color:#002e91;
      border-bottom: 1px solid #fff;
      text-align: center;
  }
  .ev-date{
    font-size: 18px;
    text-align: center;
    background-color:#fff;
    height: 36px;
    /* margin-top: -15px; */
    font-weight: bold;
  }

  .box-Img-three{
   border-radius: 10px;
}