.event-Img-three{
    width: 100%;
    border-radius: 20px;
    height: 360px;  
    object-fit: cover;
}
.category-event-box{
  display: block !important;
  border-radius: 20px;
  background-color: white;
  box-shadow: 4px 6px 19px 0 #0000001c;
  padding: .7rem;
}
.listing-text-ev{
      color: #000000;
      position: relative !important;
      background-color: #ffffff;
      font-weight: 500;
      color: rgb(27, 27, 27);
      padding-left: 8px;
      top: 0 !important;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
  }

  .category-event-box{
    position: relative;
    display: inline-block;
    margin-left: 12%;
    margin-bottom: 1rem;
    max-width: 475px;
  }


  .ev-detail-name{
    color: #002e91;
    font-weight: bold;
  }
  .event-desc{
      margin-top: 20px;
      font-size: 16px;
      color: #212529b0;
  }
  .event-desc1{
    font-size: 16px;
    color: #212529b0;
  }

  .event-main-box{
    width: 80%;
    margin-left: 12%;
    margin-top: 30px;
  }

  .concert-name{
    font-size: 22px;
  }
  .hex-txt{
    color: #002e91;
    font-size: 20px;
  }
  .event-time-box{
    display: flex;
    justify-content: space-between;
    border: 1px solid #a7a7a7;
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .user-img-style{
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border-radius: 20px;
}
.catg-review-container{
    width: 80%;
    margin-left: 12%;
    margin-top: 30px;
}

.review-btn-toggle{
  font-size: 18px;
  color: #002e91;
  margin-top: 20px;
}
.address-head{
  margin-top: 20px;
  margin-bottom: 20px;
}
.category-event-box .list-box-flex{
  display: flex !important;
}