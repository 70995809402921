.select-style{
    border: none;
    width: 150px;
    height: 30px;
}

.listing-text-ev{
    color: #000000;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 288px;
    background-color: #002e91;
    font-weight: 500;
    color: #fff;
    padding-left: 8px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.air-space-box{
    width: 82%;
    margin-left: 12%;
}

.blck-txt{
    margin-top: 20px;
}

.scroll-count{
    display: flex;
}
.inc{
    border: none;
    border-radius: 20px;
    background-color:#002e91 ;
    color: #ffffff;
    width: 30px;
    margin-right: 10px;
}

.dec{
    border: none;
    border-radius: 20px;
    background-color:#002e91 ;
    color: #ffffff;
    width: 30px;
    height: 30px;
    margin-left: 10px;
}