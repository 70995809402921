

.wrapper-side-container{
    width: 85%;
    margin: 0 auto;
    margin-top: 3%;
    margin-bottom: 30px;
}



ul li .side-link{
    color: #002e91;
    font-size: 18px;
    font-weight: 550;
    line-height: 40px; 
}

.link-style{
    text-align: center;
    background-color:#e6e4e4 ;
    height: 40px;
    width: 200px;
    margin-bottom: 10px;
    
}

 
 .side-link:hover {
    color: black;
}