@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);

.subscribe-container{
    float: right;
   }
   .rentDaap-help{
       display: flex;
       justify-content: space-between;
   }
   .rentDaap-help ul li a{
       color: #16151a !important;
       font-size: 15px;
   }
   li {
    list-style: none ;
   }
   .rentDaap-help ul li a:hover{
       color: #002e91 !important;
       cursor: pointer;
       list-style: none !important;
   }
   .footer-link{
       color:  #16151a !important;
       font-size: 18px;
       font-weight: 500;
   }
   .footer-link:hover{
       color: #002e91!important;
       cursor: pointer;
   }
   .Footer-container{
       padding:10%;
    padding-top: 6%;
    padding-bottom: 5%;
    margin-top: 40px;
    background-color: #f1f1f1;
    margin-top: 65px;
   }
   .rentDaap-Img{
       width: 30%;
       height: 150px;
   }
   
   .subscribe-rent-btn{
       font-weight: 500;
       width: 120px;
       text-decoration: none;
       height: 45px;
       border: none;
       text-align: center;
       background-color: #002e91;
       color: #fff;
   }
   .subscribe-field{
       padding-left: 10px;
       width: 235px;
       height: 45px;
   }

   .subscribe-detail{
       color:  #16151a;;
       font-size: 18px;
       font-weight: 500;
       margin-bottom: 13px;
   }
   .other-footer-txt{
       color:  #16151a;;
   }
   .social-links{
    display: flex;
    margin-left: -12px;
   }
   .social-Img{
       width: 22px;
       height: 22px;
       margin: 20px 5px 10px 5px;
   }
   .flex-box-subs{
       display: flex;
   }

   .Footer-container {
    background: white;
    border-top: 1px solid #00000038;
}
.rentDaap-help ul li a {
    text-decoration: underline;
}
.bookDaap-Img{
    width: 50px;
    height: 90px;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}
.navbar-dark .navbar-nav .nav-link{
    color: #ffffff;
}
.header-bgd-color{
    background-color: #002e91;
}
.chat-dropdown {
    line-height: 1.6;
    position: absolute;    
    border-radius: 10px;
 }
 .chat-dropdown a {
    line-height: 1;
    padding: 5px 0;
    text-decoration: underline;
    color: rgb(36, 84, 241) !important;
}
.select-style{
    border: none;
    width: 150px;
    height: 30px;
}

.listing-text-ev{
    color: #000000;
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 288px;
    background-color: #002e91;
    font-weight: 500;
    color: #fff;
    padding-left: 8px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.air-space-box{
    width: 82%;
    margin-left: 12%;
}

.blck-txt{
    margin-top: 20px;
}

.scroll-count{
    display: flex;
}
.inc{
    border: none;
    border-radius: 20px;
    background-color:#002e91 ;
    color: #ffffff;
    width: 30px;
    margin-right: 10px;
}

.dec{
    border: none;
    border-radius: 20px;
    background-color:#002e91 ;
    color: #ffffff;
    width: 30px;
    height: 30px;
    margin-left: 10px;
}
.upload-pic1{
    width: 100px;
    height: 80px;
    margin: 20px;
}

.head-create{
    margin-top: 30px;
}
 html, body {
	 font-family: helvetica, arial;
	 background: #eee;
}
 main {
	 width: 80%;
	 margin: 0 auto;
	 padding-top: 20%;
}

 .btn, button {
	 color: #fff;
	 background-color: #09f;
	 border: 1px solid #09f;
	 text-align: center;
	 display: inline-block;
	 vertical-align: middle;
	 white-space: nowrap;
	 margin: 0.6em 0.6em 0.6em 0;
	 padding: 0.35em 0.7em 0.4em;
	 text-decoration: none;
	 width: auto;
	 position: relative;
	 border-radius: 4px;
	 -webkit-user-select: none;
	     -ms-user-select: none;
	         user-select: none;
	 outline: none;
}
 .btn:hover, button:hover {
	 background-color: #ddd;
	 color: #333;
}
 .btn:active, button:active {
	 background-color: #ccc;
	 box-shadow: 0 !important;
	 top: 2px;
	 box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}


.upload-img input {
	border: 1px solid rgb(156, 154, 154);
	padding: .3rem;
	width: 96%;
	color: rgb(119, 119, 119);
	border-radius: 4px;
	margin-right: 5px;
}

.left img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.file-wrapper {
	cursor: pointer;
	display: inline-block;
	position: relative;
}

.file-wrapper input {
	cursor: pointer;
	font-size: 100px;
	height: 100%;
	filter: alpha(opacity=1);
	opacity: 0.01;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 9;
}

.left {
   width: 13rem;
   height: 8rem;
   margin: auto;
   margin-bottom: 1rem;
   border-radius: 10px;
   overflow: hidden;
}
.upload-img {
   padding: 6px;
   border: 2px dashed #0000002b;
}
.right {
   display: flex;
   align-items: center;
}

.success-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .5rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.success-header .success-icon {
  font-size: 40px;
  color: #53c42b;
  margin-bottom: 5px;
}
.success-color {
  font-size: 24px;
  color: #002E91;
  font-weight: bold;
}
.success-animation{
  position: absolute;
  top: -1rem;	
  left: -1rem;	
  bottom: -1rem;	
  right: -1rem;	
  background-image: url('https://img1.picmix.com/output/stamp/normal/8/1/8/0/1540818_fa9b6.gif');
}
.event-Img-three{
    width: 100%;
    border-radius: 20px;
    height: 360px;  
    object-fit: cover;
}
.category-event-box{
  display: block !important;
  border-radius: 20px;
  background-color: white;
  box-shadow: 4px 6px 19px 0 #0000001c;
  padding: .7rem;
}
.listing-text-ev{
      color: #000000;
      position: relative !important;
      background-color: #ffffff;
      font-weight: 500;
      color: rgb(27, 27, 27);
      padding-left: 8px;
      top: 0 !important;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
  }

  .category-event-box{
    position: relative;
    display: inline-block;
    margin-left: 12%;
    margin-bottom: 1rem;
    max-width: 475px;
  }


  .ev-detail-name{
    color: #002e91;
    font-weight: bold;
  }
  .event-desc{
      margin-top: 20px;
      font-size: 16px;
      color: #212529b0;
  }
  .event-desc1{
    font-size: 16px;
    color: #212529b0;
  }

  .event-main-box{
    width: 80%;
    margin-left: 12%;
    margin-top: 30px;
  }

  .concert-name{
    font-size: 22px;
  }
  .hex-txt{
    color: #002e91;
    font-size: 20px;
  }
  .event-time-box{
    display: flex;
    justify-content: space-between;
    border: 1px solid #a7a7a7;
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .user-img-style{
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border-radius: 20px;
}
.catg-review-container{
    width: 80%;
    margin-left: 12%;
    margin-top: 30px;
}

.review-btn-toggle{
  font-size: 18px;
  color: #002e91;
  margin-top: 20px;
}
.address-head{
  margin-top: 20px;
  margin-bottom: 20px;
}
.category-event-box .list-box-flex{
  display: flex !important;
}
.listing-Img-three {
    width: 100%;
    height: 335px;
    object-fit: cover;
    border-radius: 15px;
}
.category-three-box{
   position: relative;
   overflow: hidden;
   padding: 11px;
   border-radius: 20px;
   box-shadow: 4px 6px 19px 0 #0000001c;
}

.listing-text{
  color: #000000;
  margin: 0 auto;
  background-color: #ffffff;
  font-weight: 500;
  padding: 13px;
  font-size: 21px;
}
  .list-box-flex{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
  .location-txt{
      font-size: 15px;
  }
  .small-ev-box{
      width: 60px;
      height: 60px;
      border:1px solid #fff;
      position: absolute;
      top: 16px;
      left: 14px;
      margin: 10px;
  }
  .ev-month{
      font-size: 14px;
      color: #fff;
      background-color:#002e91;
      border-bottom: 1px solid #fff;
      text-align: center;
  }
  .ev-date{
    font-size: 18px;
    text-align: center;
    background-color:#fff;
    height: 36px;
    /* margin-top: -15px; */
    font-weight: bold;
  }

  .box-Img-three{
   border-radius: 10px;
}
.bookDaap-Img{
    width: 50px;
    height: 90px;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}
.navbar-dark .navbar-nav .nav-link{
    color: #ffffff;
}
.chat-dropdown {
    line-height: 1.6;
    position: absolute;    
    border-radius: 10px;
 }
 .chat-dropdown a {
    line-height: 1;
    padding: 5px 0;
    text-decoration: underline;
    color: rgb(36, 84, 241) !important;
}
.entertain-category{
    width: 100%;
    padding-bottom: 8%;
}
.box-Img-three{
    width: 100%;
    height: 345px;
    object-fit: cover;
    transition: all .5s;
}
.category-three-box{
   display: block !important;
   border-radius: 18px;
   background-color: white;
   box-shadow: 4px 6px 19px 0 #0000001c;
}
.second-column{
   margin-top: 30px;
}
.section-two-category .category-three-box img {
    border-radius: 18px;
}
.subtitle-en{
	text-align: center;
}
.font-weight-bold-en{
	text-align: center ;
}
.list-box-flex > a {
  font-size: 17px;
  color: #252525;
  text-decoration: underline;
}
.list-box-flex {
    display: block !important;
    justify-content: safe !important;
}
.entertain-category > h3 {
    font-weight: 550;
}

/* Common style */
.grid figure {
	position: relative;
	float: left;
	overflow: hidden;
	margin: 10px 1%;
	min-width: 320px;
	max-width: 480px;
	max-height: 360px;
	width: 48%;
	background: #3085a3;
	text-align: center;
	cursor: pointer;
}

.rentCategory-text{
    background-color: rgb(255, 255, 255);
    color: #000;
    padding: .7rem;
}

.section-two-category{
 margin-top: 30px;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.9;
}

.grid figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}

.grid figure figcaption {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
/*special styling*/
.effect-lily {
    transition: all .4s;
    border-radius: 17px;
    border: 8px solid white;
    box-shadow: 4px 6px 19px 0 #0000001c;
}
.effect-lily h3 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 0;
  color: white;
}


figure.effect-lily figcaption {
    transition: .3s;
	text-align: left;
    background: linear-gradient(rgba(0, 0, 0, 0.08),rgba(0, 0, 0, 0.925));
}

figure.effect-lily figcaption a > div {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1em;
    width: 100%;
    height: 50%;
    padding-top: 6.1rem;
    font-family: Arial, Helvetica, sans-serif;
}
.effect-lily figcaption .more {
  font-size: 14px;
  font-weight: 550;
  color: #e8e8e8;
  position: absolute;
  opacity: 0;
  transition: all .3s;
  bottom: 1rem;
  text-decoration: underline;
  cursor: pointer;
  left: 23px;
}
.effect-lily:hover figcaption .more{
  bottom: 1.5rem;
  opacity: 1;
}

.effect-lily:hover figcaption {
  background: linear-gradient(rgba(15, 15, 15, 0.08),rgb(0, 0, 0));
}

.effect-lily:hover .box-Img-three{ 
  transform: scale(1.1);
  transition: all .5s;
}
.more{
  text-decoration: underline;  
}

.effect-lily p {
    font-size: 16px;
    color: #d5d5d5;
}

figure.effect-lily h2 {
	transition: transform 0.35s;
}

.booking-hero-bgd{
    background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(/static/media/eventEleven.4b76c0ed.jpg);
    background-repeat: no-repeat;
    width:100%;
    height: 100vh;
    background-size: cover;
    border-bottom-left-radius: 1.8rem;
    border-bottom-right-radius: 1.8rem;
}

   .wrapper-container-home{
    width: 95%;
    margin: 0 auto;
}
.bookindapp-Container{
    width: 75%;
    margin: 0 auto; 
}
.hero-container{
    text-align: center;
    align-items: center;
    color: #ffffff;
    margin-top: 10%;
}
.discover-txt {
    font-size: 69px;
    margin: -21px 0;
    text-transform: uppercase;
}
.music-txt{
    font-size: 45px;
    text-align: center;
}
.music-color{
    color: #0ee0c2;
    font-size: 45px;
}


.hero-category-container{
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 50px;
}
.catg-main{
    display: flex;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 22px;

}
.choose-categr{
    font-size: 18px;
    color: #666;
    font-weight: 300;
}
.part-select{ 
    margin: 10px;
    padding-left: 40px;
    padding-right: 20px;}
   

.search-btn{
    border: 0;
    background-color: #002e91;
    color: #ffff;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    margin-top: 14px;
}
.bookingOffer-img{
    width: 85%;
    height: 400px;
    border-radius: 20px;
}
.book-box-img{
 margin-top: 30px;
 margin-bottom: 30px;
 display: flex;
 justify-content: center;
}
.ticket-wrap-para {
    font-size: 18px;
    position: relative;
    left: 30%;
    background-color: #ffff;
    z-index: 9;
    padding: 1rem;
    top: 13%;
    border-radius: 12px;
    box-shadow: 1px 9px 23px 0px #0000002f;
    color: #4f4f4f;
}
.sideticket-img{
    width: 100%;
    height: 380px;
    object-fit: cover;
    border-radius: 18px;
}
.margin-left-ticket{
    margin-left: 20px;
}
.sub-tick-txt{
    display: flex;
    justify-content:space-evenly;
    margin-top: 30%;
}
.text-red{
    color: #d9072a 
}
.started-box{
    display: flex;
    justify-content: center;
    margin-right: 10%;
}
.Started-btn-btn{
    border: 0;
    background-color: #002e91;
    color: #ffff;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    margin-top: 14px; 
}
.org-head-txt{
    color: #16151a;
    font-size: 18px;
    font-weight: bold;
}
.user-img-pf{
    width: 40px;
    height: 40px;
    border-radius: 40px;
}
.pb-3-txt{
    margin: 5px;
  font-size: 18px;
  color: #002e91 !important;
}

@media (max-width: 768px) {
  
    .catg-main{
        display: block;
    
    }
    .container{
        margin-top: 20px;
    }
    .bookingOffer-img {
        width: 100%;
        height: 160px;
    }   
    .ticket-wrap-para {
        font-size: 18px;
        left:0;
        background-color: #ffff;
        z-index: 9;
        padding: 22px;
        top:0;
    }
    .sub-tick-txt{
        margin-top: 70%;
    }
    .pb-3-txt {
        margin: 5px;
        font-size: 10px;
        color: #002e91 !important;
    }
    .rentDaap-Img {
        width: 20% !important;
        height: 110px;
    }
    .discover-txt {
        font-size: 36px;
        margin-top: -7px;
    }
    .music-txt {
        font-size: 35px;
        text-align: center;
    }
    .music-color {
        color: #0ee0c2;
        font-size: 30px;
    }
}

.hero-category-container input:focus{
  box-shadow: none;   
}

.hero-category-container h6 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px;
}
.hero-category-container input,.hero-category-container select {
  outline: none;
  border: none;
  padding: 0;
}
.catg-main{
  padding: 0;
  border-radius: 12px !important;   
}
.part-select {
  padding-right: 0 !important;
  padding-left: 25px !important;
  margin-bottom: 0;
}
.part-select button {
  cursor: pointer;
}

button{
  cursor: pointer;  
}

.section-two-category > .row [class*='col-md-4']{
  display: flex;  
}
.text-clamp-1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.event-name{
  font-size: 17px !important;
  color: #002e91 !important;
  font-weight: 550;
  text-decoration: none !important;
}
.hero-category-container .form-select {
  background: white;
}


#video .container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
  }
  .reflection-text {
    font-size: 2.2rem;
    font-weight: 550;
    margin-bottom: -2px;
  }
  #video .container p {
    margin-bottom: 1rem;
  }
  #video .container iframe {
    border-radius: 12px;
  }
  @media screen and (max-width: 500px) {
    #video iframe{
      width: 100%;
      height: 237px;  
    }
}



.wrapper-side-container{
    width: 85%;
    margin: 0 auto;
    margin-top: 3%;
    margin-bottom: 30px;
}



ul li .side-link{
    color: #002e91;
    font-size: 18px;
    font-weight: 550;
    line-height: 40px; 
}

.link-style{
    text-align: center;
    background-color:#e6e4e4 ;
    height: 40px;
    width: 200px;
    margin-bottom: 10px;
    
}

 
 .side-link:hover {
    color: black;
}
//constants are used for string literals
export default {
	testContant: 'Testing'
};

.view-details{
	font-size: 14px;
	color: #484b7b;
}
.form-submit-btn{
    border: 0;
    width: 100px;
    height: 30px;
    color: #ffffff;
    background-color: #002e91;
    margin-top: 30px;
}
.fieldone-txt{
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
    padding: 10px;
}
.qs-field{
    width: 100%;
    height: 80px;
    margin-bottom: 20px;
    padding: 10px;
}
.field-note-txt{
    font-size: 14px;
    color: 	#727272;
    margin-bottom: 0px;
}

.img-upload{
    border: 1px solid #a7a7a7;
    width: 100px;
    height: 100px;
    margin: 10px;
   }

   .upload-pic{
    width: 40px;
    margin-top: 25px;
    margin-left: 25px;
    height: 40px
}

.upload-picture{
    width: 60%;
    margin-top: 10px;
    margin-left: 10px;
}
.upload-img-container{
    display: flex;
}
.color-note{
    color: #002e91;
    font-weight: 600;
    font-size: 18px;  
}
.grey-note{
    font-size: 18px;
    font-weight: 600;
    color: #727272;
}
.qr-img-scan{
    width: 120px;
    height: 120px; 
    margin-left: 10px;
    margin-top: 10px;  
}
.tickt-detail{
    display: flex;
    background-color: #a7a7a747;
}
.color-name{
    color: #002e91;
    font-weight: 600;
    font-size: 18px;  
}
.date-ticket{
    display: flex;
}
p{
    margin-bottom: 0;
}
.container-mytick{
    margin-left: 20px;
}
.no-tick{
    text-align: center;
    background-color: #002e91;
    color: #ffffff;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.tickt-detail{
    margin-top: 10px;
}
.qr-img-scan{
    width: 120px;
    height: 120px; 
    margin-left: 10px;
    margin-top: 10px;  
}
.tickt-detail{
    display: flex;
    background-color: #a7a7a747;
}
.color-name{
    color: #002e91;
    font-weight: 600;
    font-size: 18px;  
}
.date-ticket{
    display: flex;
}
p{
    margin-bottom: 0;
}
.container-mytick{
    margin-left: 20px;
}
.no-tick{
    text-align: center;
    background-color: #002e91;
    color: #ffffff;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.tickt-detail{
    margin-top: 10px;
}
.ticket-bgd-img{
    background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(/static/media/create.fd25ed8f.jpg);
    background-repeat: no-repeat;
    width:100%;
    height: 40vh;
    background-size: cover;  
    margin-top: -4px;
}

.tick-heading{
    color: #ffffff;
    text-align: left;
    width: 70%;
    margin: 0 auto;
    padding-top: 15%;
    font-size: 40px;
    font-weight: bold;
}

.two-tick-option{
 display: flex;
 justify-content: flex-end;
}

.tick-txt-opt{
    font-size: 20px;
    margin-left: 20px;
    margin-top: 20px;
    color: #000000 !important;
}

/*add all your css to src/lib/GlobalStyles.ts file */
/*==================== GOOGLE FONTS ====================*/


/*----------------
margin space
-----------------*/

.mb0 { margin-bottom: 0px; }
.mb5 { margin-bottom: 5px; }
.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }
.mb60 { margin-bottom: 60px; }
.mb80 { margin-bottom: 80px; }
.mb100 { margin-bottom: 100px; }
.mt0 { margin-top: 0px; }
.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }
.mt60 { margin-top: 60px; }
.mt80 { margin-top: 80px; }
.mt100 { margin-top: 100px; }
.mr0 { margin-right: 0px; }
.mr10 { margin-right: 10px; }
.mr20 { margin-right: 10px; }
.ml0 { margin-left: 0px; }
.ml10 { margin-left: 10px; }
.ml20 { margin-left: 20px; }

/*----------------
less margin space from top
-----------------*/
.mt-20 { margin-top: -20px; }
.mt-40 { margin-top: -40px; }
.mt-60 { margin-top: -60px; }
.mt-80 { margin-top: -80px; }

/*---------------
Padding space
-----------------*/
.nopadding { padding: 0px; }
.nopr { padding-right: 0px; }
.nopl { padding-left: 0px; }
.pinside10 { padding: 10px; }
.pinside20 { padding: 20px; }
.pinside30 { padding: 30px; }
.pinside40 { padding: 40px; }
.pinside50 { padding: 50px; }
.pinside60 { padding: 60px; }
.pinside80 { padding: 80px; }
.pinside100 { padding: 100px; }
.pdt10 { padding-top: 10px; }
.pdt20 { padding-top: 20px; }
.pdt30 { padding-top: 30px; }
.pdt40 { padding-top: 40px; }
.pdt60 { padding-top: 60px; }
.pdt80 { padding-top: 80px; }
.pdb10 { padding-bottom: 10px; }
.pdb20 { padding-bottom: 20px; }
.pdb30 { padding-bottom: 30px; }
.pdb40 { padding-bottom: 40px; }
.pdb60 { padding-bottom: 60px; }
.pdb80 { padding-bottom: 80px; }
.pdl10 { padding-left: 10px; }
.pdl20 { padding-left: 20px; }
.pdl30 { padding-left: 30px; }
.pdl40 { padding-left: 40px; }
.pdl60 { padding-left: 60px; }
.pdl70 { padding-left: 70px; }
.pdl80 { padding-left: 80px; }
.pdl120 { padding-left: 120px; }
.pdr10 { padding-right: 10px; }
.pdr20 { padding-right: 20px; }
.pdr30 { padding-right: 30px; }
.pdr40 { padding-right: 40px; }
.pdr60 { padding-right: 60px; }
.pdr80 { padding-right: 80px; }
* {
padding: 0;
margin: 0;
}
body{
  background-color: #f9f9f9 !important;
  font-family: "Poppins", sans-serif !important;
}
.clearfix{
  clear: both;
}
.com-btn{
background: #e77886;
color:#fff;
}
.theme-text{
color:#e77886;
}
.navbar-default.scrolled{
  background:#fddddd;
  box-shadow: 0px 3px 6px #0000003d;
}
.navbar-dark .navbar-nav .nav-link {
  color: #805b5f;
  font-weight: 600;
}
.navbar-dark .navbar-nav .active>.nav-link{
color: #fff;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus{
  color: #fff !important;
}
.section{
  padding: 2rem 0;
}
.container {
  padding: 2.4rem 0 1rem 0rem ;
}
.transition-in{
  -webkit-animation: transitionIn .6s;
          animation: transitionIn .6s;
}


@-webkit-keyframes transitionIn {
  0%{
    opacity:0;
    transform: scale(.8);
  }

  100%{
    opacity:1;
    transform: scale(1);
  }
}


@keyframes transitionIn {
  0%{
    opacity:0;
    transform: scale(.8);
  }

  100%{
    opacity:1;
    transform: scale(1);
  }
}
