.ticket-bgd-img{
    background-image: linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url('../../assets/Images/create.jpg');
    background-repeat: no-repeat;
    width:100%;
    height: 40vh;
    background-size: cover;  
    margin-top: -4px;
}

.tick-heading{
    color: #ffffff;
    text-align: left;
    width: 70%;
    margin: 0 auto;
    padding-top: 15%;
    font-size: 40px;
    font-weight: bold;
}

.two-tick-option{
 display: flex;
 justify-content: flex-end;
}

.tick-txt-opt{
    font-size: 20px;
    margin-left: 20px;
    margin-top: 20px;
    color: #000000 !important;
}