
.subscribe-container{
    float: right;
   }
   .rentDaap-help{
       display: flex;
       justify-content: space-between;
   }
   .rentDaap-help ul li a{
       color: #16151a !important;
       font-size: 15px;
   }
   li {
    list-style: none ;
   }
   .rentDaap-help ul li a:hover{
       color: #002e91 !important;
       cursor: pointer;
       list-style: none !important;
   }
   .footer-link{
       color:  #16151a !important;
       font-size: 18px;
       font-weight: 500;
   }
   .footer-link:hover{
       color: #002e91!important;
       cursor: pointer;
   }
   .Footer-container{
       padding:10%;
    padding-top: 6%;
    padding-bottom: 5%;
    margin-top: 40px;
    background-color: #f1f1f1;
    margin-top: 65px;
   }
   .rentDaap-Img{
       width: 30%;
       height: 150px;
   }
   
   .subscribe-rent-btn{
       font-weight: 500;
       width: 120px;
       text-decoration: none;
       height: 45px;
       border: none;
       text-align: center;
       background-color: #002e91;
       color: #fff;
   }
   .subscribe-field{
       padding-left: 10px;
       width: 235px;
       height: 45px;
   }

   .subscribe-detail{
       color:  #16151a;;
       font-size: 18px;
       font-weight: 500;
       margin-bottom: 13px;
   }
   .other-footer-txt{
       color:  #16151a;;
   }
   .social-links{
    display: flex;
    margin-left: -12px;
   }
   .social-Img{
       width: 22px;
       height: 22px;
       margin: 20px 5px 10px 5px;
   }
   .flex-box-subs{
       display: flex;
   }

   .Footer-container {
    background: white;
    border-top: 1px solid #00000038;
}
.rentDaap-help ul li a {
    text-decoration: underline;
}